import React from "react";
import editIcon from "../../images/icons/edit.svg";
import deleteIcon from "../../images/icons/delete.svg";

import "./Users.css";

import EditUser from "./EditUser";
import { SpaceBetween } from "../../styledComponents/Layout";
import SearchInput from "../../components/Inputs/SearchInput/SearchInput";
import { WarningPopUp } from "../PopUps/WarningPopup";
import Table from "../../components/tables/Table";
import TableCell from "../../components/tables/TableCell/TableCell";
import TableRow from "../../components/tables/TableRow/TableRow";
import { TextGrey } from "../../styledComponents/Text";
import GreenButton from "../../components/buttons/GreenBuuton.js/GreenButton";
import { useAlert } from "react-alert";
import { createUser, deleteUser, getUsers, updateUser } from "../../api/users";
import regions from "../../constants/regions";
import rolesTL from "../../constants/roles";
import userTypes from "../../constants/userTypes";

const GRID_TEMPLATE = "30fr 200fr 150fr 150fr 170fr 135fr 80fr 80fr";

const UserRegion = ({ region }) => {
  if (!region) {
    return "<не вказаний>";
  }

  return typeof region !== "string"
    ? `${region.map((key) => regions[key].name)}`
    : regions[region].name || region;
};

const UserRoles = ({ roles = [] }) => {
  if (roles.length === 0) {
    return "<немає ролей>";
  }
  return roles
    .map((role) => rolesTL[role] || (role === "ROLE_STAFF" ? null : role))
    .filter((el) => !!el)
    .join(", ");
};

const UserRow = ({ index, user, setDeleteItem, setEditUser }) => {
  const { firstName, lastName, middleName, email, userType, telephone } = user;

  return (
    <TableRow onClick={() => setEditUser(user)} gridTemplate={GRID_TEMPLATE}>
      <TableCell justify="center">{index + 1}</TableCell>
      <TableCell>
        {firstName} {lastName} {middleName}
      </TableCell>
      <TableCell>
        {email === "" || email === null ? telephone : email}
      </TableCell>
      <TableCell>{userTypes[userType]?.name || userType}</TableCell>
      <TableCell>
        {user.roles[0] !== "ROLE_STAFF" ? (
          <UserRoles roles={user.roles} />
        ) : (
          "<немає ролей>"
        )}
      </TableCell>
      <TableCell>
        <UserRegion region={user.region} />
      </TableCell>
      <TableCell>
        {user.NotificationTelegram ? "Підключен" : "Не підключен"}
      </TableCell>
      {/* <TableCell></TableCell> */}
      <TableCell justify="center">
        <button onClick={() => setDeleteItem(user)}>
          <img src={deleteIcon} />
        </button>
      </TableCell>
    </TableRow>
  );
};

const PlaceholderRow = ({ children }) => {
  return (
    <TableRow type="header" gridTemplate={GRID_TEMPLATE}>
      <TableCell></TableCell>
      <TableCell>{children}</TableCell>
    </TableRow>
  );
};

const matches = (needle, haystack) => {
  needle = needle.toLocaleLowerCase();
  haystack = (haystack || "").toLocaleLowerCase();
  return haystack.includes(needle);
};

const deleteView = (user) =>
  `${user.firstName} ${user.lastName} ${
    user.email === "" ? user.telephone : user.email
  }`;

const defaultUser = {
  firstName: "",
  lastName: "",
  middleName: "",
  email: "",
  telephone: "",
  password: "",
  roles: [],
  userType: "administrator",
  region: [],
};

export const Users = (props) => {
  const [search, setSearch] = React.useState("");
  const [users, setUsers] = React.useState(null);
  const [deleteItem, setDeleteItem] = React.useState(null);
  const [editUser, setEditUser] = React.useState(null);
  const alert = useAlert();

  const updateUsers = () => {
    getUsers().then(({ users }) => {
      setUsers(users);
    });
  };
  React.useEffect(updateUsers, []);

  let filtered = users || [];
  if (filtered.length > 0 && search.length > 0) {
    const fields = [
      (user) => user.firstName,
      (user) => user.lastName,
      (user) => user.email,
      (user) => user.telephone,
      (user) => regions[user.region]?.name,
      (user) => userTypes[user.userType]?.name,
    ];
    filtered = filtered.filter((user) =>
      fields.some((field) => matches(search, field(user)))
    );
  }

  const saveUser = async (userData) => {
    try {
      const { _id, ...update } = userData;
      let response;
      if (_id) {
        response = await updateUser(_id, update);
      } else {
        response = await createUser(update);
      }
      if (!response.success) {
        throw new Error("Щось не так");
      }
      setEditUser(null);
      updateUsers();
    } catch (error) {
      alert.show(error.message, { type: "error" });
    }
  };

  return (
    <div>
      <SpaceBetween>
        <TextGrey>Облікові записи користивачів</TextGrey>
        <SearchInput
          placeholder="Пошук"
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          value={search}
          clearInputFunc={() => setSearch("")}
        />
        <div style={{ position: "relative" }}>
          <GreenButton onClick={() => setEditUser(defaultUser)}>
            <span>+ Додати користувача</span>
          </GreenButton>
        </div>
      </SpaceBetween>
      <Table>
        <TableRow type="header" gridTemplate={GRID_TEMPLATE}>
          <TableCell justify="center">№</TableCell>
          <TableCell>ПІБ</TableCell>
          <TableCell>Логін</TableCell>
          <TableCell>Посада</TableCell>
          <TableCell>Доступ</TableCell>
          <TableCell>Регіон</TableCell>
          <TableCell>Telegram</TableCell>
          {/* <TableCell>Оформлений</TableCell> */}
          <TableCell justify="center">Видалити</TableCell>
        </TableRow>

        {filtered?.length ? (
          filtered.map((user, index) => (
            <UserRow
              key={index}
              index={index}
              user={user}
              setDeleteItem={setDeleteItem}
              setEditUser={setEditUser}
            />
          ))
        ) : (
          <PlaceholderRow>Немає користувачів</PlaceholderRow>
        )}
      </Table>
      {(editUser || deleteItem) && <div className="bg__layer"></div>}
      {deleteItem && (
        <WarningPopUp
          onDelete={() => {
            deleteUser(deleteItem._id).then(updateUsers);
            setDeleteItem(null);
          }}
          toggleWarningModal={() => setDeleteItem(null)}
          name={deleteView(deleteItem)}
        />
      )}
      {editUser && (
        <EditUser
          setEditUser={setEditUser}
          userData={editUser}
          saveUser={saveUser}
        />
      )}
    </div>
  );
};
