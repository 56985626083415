import regions from "../../constants/regions";

const statusUser = {
  vacation: "Відпустка",
  deleted: "Звільнений",
  work: "Працює",
  cancellation: "Відмінений",
};

export const Table = (props) => {
  return (
    <table>
      <tbody>
        {props.table_data.map((e, i) => (
          <tr onClick={() => props.set_user(e)} key={i}>
            <td>{i + 1}</td>
            <td>
              {e.surname} {e.name} {e.patronymic}
            </td>
            <td>
              {e.postInfo.status === "deleted"
                ? "Посада відсутня"
                : e.postInfo.status}
            </td>
            <td>{e.object ? regions[e.object.region].name : ""}</td>
            <td>
              {e.postInfo.currentStatus
                ? statusUser[e.postInfo.currentStatus]
                : statusUser[e.postInfo.status]
                ? statusUser[e.postInfo.status]
                : "Працює"}
            </td>
            <td>{e.telegram ? "Підписан" : "Не підписан"}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
